<template>
  <div class="serial">
    <div class="header">
      <div class="headerA">欢迎来到希敏佳®官方网站</div>
      <div class="headerB">
        <div class="headerB_one">
          <img class="img1" src="../assets/img/serial/break.png" alt="break" @click="breaks">
          <img class="img2" src="../assets/img/hedar/topLog.png" alt="log" >
        </div>
      </div>
    </div>

    <div class="welcome">
      <img src="../assets/img/serial/bg2.png" alt="bg2" class="bg2">
      <img src="../assets/img/serial/bg1.png" alt="Images" class="appbg1">
      <div class="boxs">
        <div class="ImagesConner">
        <p class="ImagesConner_title">欢迎来到<br/>希敏佳®官方网站</p>
        <div class="ImagesConner_text">
          <img class="ImagesConner_textA" src="../assets/img/serial/read.png" alt="read">
          <div class="ImagesConner_textB">
            <p class="ImagesConner_textB_title">请输入产品批号：</p>
            <p class="ImagesConner_textB_conner">为了更好，更合规的提供相关产品知识，我们需要确认每一位网站的访问者是正在使用我们希敏佳的患者，请在希敏佳的包装外壳上找到产品批号，输入到右边的框中，以便浏览相关产品内容。感谢您的理解和支持。</p>
            <p class="ImagesConner_textB_conner2">为了更好，更合规地提供相关知识和信息给到正在使用希敏佳产品的患者，我们需要您在浏览本网站相关专业内容前输入产品批号，感谢您的理解和支持。</p>
          </div>
        </div>

        <div class="ImagesConner_tags">
          <div><img src="../assets/img/serial/check.png" alt="check"> 给您更精确的健康知识推送</div>
          <div class="checkimgs"><img src="../assets/img/serial/check.png" alt="check" > 与更多使用希敏佳产品的患者交流经验</div>
        </div>
        <div class="ImagesConner_tags2">
          <div><img src="../assets/img/serial/check.png" alt="check"> 实时了解最新健康资讯</div>
          <div class="checkimgs2"><img src="../assets/img/serial/check.png" alt="check" > 专业团队为能提供一对一健康方案</div>
        </div>

        <div class="ImagesConner_button">
          <el-input
            placeholder="请输入产品批次号"
            v-model="serialCode"
            maxlength="6">
            <img slot="prefix" src="../assets/img/serial/boxs.png">
          </el-input>

          <div class="submitButton" @click="submitButton">提交</div>
        </div>
      </div>
        <img src="../assets/img/serial/bg1.png" alt="Images" class="bg1">
      
      </div>
      
    </div>


    <div class="footer">
      <div class="footer_text">
        <div class="footer_textboxA">
          <p class="footer_textboxA_text1" @click="toContact" style="cursor: pointer;">联系我们</p>
          <p class="footer_textboxA_text2">您或您的亲属、朋友，使用优时比产品时有任何问题或是对UCB产品或相关疾病领域有任何医学相关的咨询，可以点击链接查看我们的联系方式，可随时通过邮箱、电话、传真联系我们。</p>

          <div class="footer_textboxA_icon">
            <a class="icon1" href="https://www.ucbchina.com/Contact"></a>
            <a class="icon2" href="https://www.ucbchina.com/Contact"></a>
            <a class="icon3" href="https://www.ucbchina.com/Contact"></a>
            <a class="icon4" href="https://www.ucbchina.com/Contact"></a>
          </div>
        </div>
        <div class="footer_textboxB">
          <div class="footer_textboxB_textA">
            <a class="title" @click="toOutLink(2)">法律隐私声明</a>
            <a @click="toOutLink(3)">cookie声明</a>
          </div>

          <div class="footer_textboxB_textB">
            <p class="title" @click="toDT" style="cursor: pointer;">网站地图</p>
            <a @click="toHome">主页</a>
            <a @click="toVideo" >注射视频</a>
            <a @click="toBook">说明书</a>
            <a @click="toPh">附近药房</a>
            <a href="https://www.ucbchina.com/Contact">药品不良反应报告</a>
          </div>
        </div>
      </div>
      <p class="footerBrand">© 2023 优时比贸易（上海）有限公司 All rights reserved</p>
      <p class="footerBrand" style="margin: 0;">沪ICP备11017794号-11</p>
    </div>
  </div>
</template>

<script>
import '../style/serial/PCstyle.scss'
import '../style/serial/APPstyle.scss'
import { sha256 } from 'js-sha256';
import axios from 'axios'

export default {
  name: 'HomeView',
  components: {

  },
  data () {
    return {
      serialCode:'',
      serialNumbers:[]
    }
  },
  created () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    axios.request({
       url: '/JSON/serialNumber.json',//直接填写json文件在public下的路径即可
       method: 'get',
      }).then(res => {
        this.serialNumbers = res.data.serialNumber
      })
  },
  methods: {
    submitButton(){
        const encryptedString = sha256(this.serialCode+'53f77ff42e46')
      const isOne = this.serialNumbers.indexOf(encryptedString)
      const thens = this
      if(isOne<0){
        this.$confirm('输入的产品批次号错误, 请检查后重新输入', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton:false,
          showClose:false,
          confirmButtonClass:'confirmButtonClass',
          customClass:'customClass'
        }).then(() => {
          thens.serialCode = ''
        }).catch(() => {
          thens.serialCode = ''
        });
      }else{
        const SerialType = sessionStorage.getItem('SerialType')
        sessionStorage.setItem('tableIndex',SerialType)
        sessionStorage.setItem('isOkTypeA',true)
        
        this.$router.replace('/home')
      }
    },
    breaks(){
      sessionStorage.setItem('SerialType',0)
      sessionStorage.setItem('tableIndex',0)
      this.$router.replace('/home')
    },
    toOutLink(index){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      sessionStorage.setItem('tableIndex',4)
      sessionStorage.setItem('showindex',index)
      this.$router.replace('/home')
    },
    toHome(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      sessionStorage.setItem('SerialType',0)
      sessionStorage.setItem('tableIndex',0)
      this.$router.replace('/home')
    },
    toDT(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      sessionStorage.setItem('SerialType',0)
      sessionStorage.setItem('tableIndex',5)
      this.$router.replace('/home')
    },
    toPh(){
      if(sessionStorage.getItem('isOkTypeA')){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        sessionStorage.setItem('SerialType',0)
        sessionStorage.setItem('tableIndex',3)
        this.$router.replace('/home')
        }
    },
    toVideo(){
      if(sessionStorage.getItem('isOkTypeA')){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        sessionStorage.setItem('SerialType',0)
        sessionStorage.setItem('tableIndex',1)
        this.$router.replace('/home')
        }
    },
    toBook(){
      if(sessionStorage.getItem('isOkTypeA')){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        sessionStorage.setItem('SerialType',0)
        sessionStorage.setItem('tableIndex',2)
        this.$router.replace('/home')
        }
    },
    toContact(){
      window.location.href='https://www.ucbchina.com/Contact';
    }
  }
}
</script>

