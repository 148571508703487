<template>
  <div class="home">
      <div class="header">
        <div class="headerA">欢迎来到希敏佳®官方网站</div>
        <div class="headerB">
          <div class="headerB_one">
            <img src="../assets/img/hedar/topLog.png" alt="log" class="logstyle" >
          </div>
          <div class="headerB_two">
            <span :class="tabindx===0?'spanboder':''" @click="clicktab(0)">主页</span>
            <span :class="tabindx===1?'spanboder':''" @click="clicktab(1)">注射视频</span>
            <span :class="tabindx===2?'spanboder':''" @click="clicktab(2)">说明书</span>
            <span :class="tabindx===3?'spanboder':''" @click="clicktab(3)">附近药房</span>
          </div>

          <div class="moblieMenus" @click="showmenuBox=!showmenuBox"></div>
        </div>
        <div class="menuBox" v-show="showmenuBox">
            <div class="menuBoxInner">
              <div class="offbutom"><i class="el-icon-close" @click="showmenuBox=false"></i></div>
              <div class="menusList"  @click="clicktab(0)">
                <div class="icons"></div>
                <div class="menusName">首页</div>
                <div class="iconsTWO"></div>
              </div>

              <div class="menusList"  @click="clicktab(1)">
                <div class="icons2"></div>
                <div class="menusName">注射视频</div>
                <div class="iconsTWO"></div>
              </div>

              <div class="menusList"  @click="clicktab(2)">
                <div class="icons3"></div>
                <div class="menusName">说明书</div>
                <div class="iconsTWO"></div>
              </div>

              <div class="menusList"  @click="clicktab(3)">
                <div class="icons4"></div>
                <div class="menusName">附近药房</div>
                <div class="iconsTWO"></div>
              </div>
            </div>
            
        </div>
      </div>

    <HomePage :getHomeData="getHomeData" v-if="tabindx===0" class="compStyle" :style="showmenuBox?'height: 100px;overflow: hidden;':''" />
    <PharmacyPage v-if="tabindx===3"  class="compStyle"  :style="showmenuBox?'height: 100px;overflow: hidden;':''"/>
    <InstructionsPage v-if="tabindx===2" class="compStyle" :style="showmenuBox?'height: 100px;overflow: hidden;':''" />
    <VideoPage v-if="tabindx===1" class="compStyle" :style="showmenuBox?'height: 100px;overflow: hidden;':''" />

    <ContactPage v-if="tabindx===4" :showindex="showindex"/>

    <div class="wzdt" v-show="tabindx===5">
      <p class="title">网站地图</p>
      <p class="texts">
        <span @click="clicktab(0)">主页</span>
        <span @click="clicktab(1)" >注射视频</span>
        <span @click="clicktab(2)">说明书</span>
        <span @click="clicktab(3)">附近药房</span>

      </p>
    </div>

    <div style="width: 100%;background: linear-gradient(to right, #58386B 0%, #3C4A90 100%);">
      <div class="footer">
      <div class="footer_text">
        <div class="footer_textboxA">
          <p class="footer_textboxA_text1" @click="toContact">联系我们</p>
          <p class="footer_textboxA_text2">您或您的亲属、朋友，使用优时比产品时有任何问题或是对UCB产品或相关疾病领域有任何医学相关的咨询，可以点击链接查看我们的联系方式，可随时通过邮箱、电话、传真联系我们。</p>

          <div class="footer_textboxA_icon">
            <a class="icon1" href="https://www.ucbchina.com/Contact"></a>
            <a class="icon2" href="https://www.ucbchina.com/Contact"></a>
            <a class="icon3" href="https://www.ucbchina.com/Contact"></a>
            <a class="icon4" href="https://www.ucbchina.com/Contact"></a>
          </div>
        </div>
        <div class="footer_textboxB">
          <div class="footer_textboxB_textA">
            <a class="title" @click="toOutLink(2)">法律隐私声明</a>
            <a @click="toOutLink(3)">cookie声明</a>
          </div>

          <div class="footer_textboxB_textB">
            <p class="title" @click="tabindx=5;" style="cursor: pointer;">网站地图</p>
            <a @click="toHomes">主页</a>
            <a @click="toVideo" >注射视频</a>
            <a @click="toBook">说明书</a>
            <a @click="toPh">附近药房</a>
            <a href="https://www.ucbchina.com/Contact">药品不良反应报告</a>
          </div>
        </div>
      </div>
      <p class="footerBrand">© 2023 优时比贸易（上海）有限公司 All rights reserved</p>
      <p class="footerBrand" style="margin: 0;">沪ICP备11017794号-11</p>
    </div>
    </div>
  </div>
</template>

<script>
import '../style/header/PCstyle.scss'
import '../style/header/APPstyle.scss'

// @ is an alias to /src
import HomePage from '@/components/home.vue'
import PharmacyPage from '@/components/pharmacy.vue'
import InstructionsPage from '@/components/instructions.vue'
import VideoPage from '@/components/video.vue'

import ContactPage from '@/components/contact.vue'

export default {
  name: 'HomeView',
  components: {
    HomePage,
    PharmacyPage,
    InstructionsPage,
    VideoPage,
    ContactPage
  },
  data () {
    return {
      tabindx:0,
      isSerch:false,
      showmenuBox:false,
      showindex:0
    }
  },
  created () {
    const index = sessionStorage.getItem('tableIndex')?sessionStorage.getItem('tableIndex')*1:0
    this.tabindx = index
    const showindex = sessionStorage.getItem('showindex')?sessionStorage.getItem('showindex')*1:0
    this.showindex = showindex
  },
  methods: {
    clicktab(index){
      sessionStorage.setItem('provinceName','')
      sessionStorage.setItem('citysName','')

      if(index===1){
        
        if(sessionStorage.getItem('isOkTypeA')){
          this.tabindx = 1
          this.showmenuBox=false
        }else{
          sessionStorage.setItem('SerialType',1)
          this.$router.push('/Serial')
        }
      }else if(index===2){
        if(sessionStorage.getItem('isOkTypeA')){
          this.tabindx = 2
          this.showmenuBox=false
        }else{
          sessionStorage.setItem('SerialType',2)
          this.$router.push('/Serial')
        }
      }else if(index===3){
        if(sessionStorage.getItem('isOkTypeA')){
          this.tabindx = 3
          this.showmenuBox=false
        }else{
          sessionStorage.setItem('SerialType',3)
          this.$router.push('/Serial')
        }
        // sessionStorage.setItem('SerialType','')
        // this.tabindx = index
        // this.showmenuBox=false
        // sessionStorage.setItem('tableIndex',this.tabindx)
        // document.body.scrollTop = document.documentElement.scrollTop = 0;
      }else{
        sessionStorage.setItem('SerialType','')
        this.tabindx = index
        this.showmenuBox=false
        sessionStorage.setItem('tableIndex',this.tabindx)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
      
    },
    // 获取子组件数据
    getHomeData(data){
      this.isSerch = true
      this.tabindx = data
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    toHomes(){
      this.tabindx=0
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    toPh(){
      // this.tabindx=3
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
      if(sessionStorage.getItem('isOkTypeA')){
          this.tabindx = 3
          this.showmenuBox=false
        }else{
          sessionStorage.setItem('SerialType',3)
          this.$router.push('/Serial')
        }
    },
    toVideo(){
      if(sessionStorage.getItem('isOkTypeA')){
          this.tabindx = 1
          this.showmenuBox=false
        }else{
          sessionStorage.setItem('SerialType',1)
          this.$router.push('/Serial')
        }
    },
    toBook(){
      if(sessionStorage.getItem('isOkTypeA')){
          this.tabindx = 2
          this.showmenuBox=false
        }else{
          sessionStorage.setItem('SerialType',2)
          this.$router.push('/Serial')
        }
    },
    toOutLink(index){
      this.showindex=index;
      this.tabindx=4;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    toContact(){
      window.location.href='https://www.ucbchina.com/Contact';
    }
  }
}
</script>

