<template>
  <div class="contact">
    <div class="nobox"></div>
    <div class="lxwm" v-show="showindex===1">
      <p class="title">联系我们</p>
      <div class="connerBox">
          <div class="imgs"><img src="../assets/textlogo.jpg" alt="textlogo"></div>
          <div class="textBox">
             <div class="textA">
                <p>工作时间 : 周一至周五 9：00 - 18：00</p>
                <p class="p2">优时比中国 地址：上海市西藏中路268号来福士广场办公楼2802室</p>
                <p>邮编：200001</p>
                <p>电话：+8621-23210288</p>
                <p>传真：+8621-23210399/23210397</p>
             </div>
             <div class="textB">
                <p>1: 如果您或您的亲属、朋友，使用优时比产品后发生了不良事件（不良反应），请联系：</p>
                <p>邮箱：Ds.china@ucb.com</p>
                <p>2: 如有任何关于产品质量的投诉，请联系我们：</p>
                <p>电话：800 820 6339-分拨2（工作时间：法定工作日09：00-17：00）</p>
                <p>邮箱：China_Complaint@ucb.com</p>
                <p>3: 如果您对UCB产品或相关疾病领域有任何医学相关的咨询，请联系：</p>
                <p>电话：800 820 6339-分拨1（法定工作时间：工作日09：00-17：00）</p>
                <p>邮箱: MedicalInformationChina@ucb.com</p>
             </div>
          </div>
      </div>
    </div>
    <div class="flys" v-show="showindex===2">
      <p class="title">法律隐私及声明</p>
      <p class="title2">总则</p>
      <p class="texts">优时比重视您的隐私。我们希望通过本隐私政策向您说明优时比对您在访问本网站时所提供信息的收集、使用和披露的方式。请您务必在于本网站使用或提交任何能够用于识别您身份的个人信息，例如您的姓名，邮寄地址，电子邮件地址，电话号码，IP地址，简历等（“个人信息”）之前完整阅读本隐私政策。本隐私政策仅适用于在本网站使用和收集信息，不适用于优时比通过线上或线下方式收集的其它信息。
      </p>
      <p class="texts">如果您未满18周岁或不具有完全的民事行为能力及民事权利能力，请立即离开本网站。</p>
      <p class="texts">当您在本网站注册时，您需要提供特定信息（部分信息必须提供，其余由您自选）或选取用户名及密码。如果您不愿提供所需的个人信息，您可能无法在本网站注册并获取对您的询问的反馈。</p>
      <p class="texts">优时比承诺您所提供的个人信息将仅用于回答您的询问或查询信息。优时比不会为直接销售目的使用您的个人信息。</p>
      <p class="texts">您应当对您的用户名及密码保密。对因您个人泄露账户信息及密码而造成的任何后果，优时比不承担任何法律责任。如果您发现任何未经您同意使用您的账户和个人信息的情况，请立即通知优时比。</p>
      <p class="texts">求职人员。如果您因求职申请之目的提交了您的个人信息，则其将按下述方式被使用：为招聘，任用及聘用之目的；为填补职位空缺所作的材 料推荐及匹配之目的；就未来可能的职业机会向您发出通知。您的信息将会被保存在优时比招聘系统中。如果您没有加入优时比，您可以要求我们删除该信息。如果 您申请成功，您的信息将被转移并保存至您的雇用档案中。</p>
      <p class="texts">转移。出于本隐私政策所述之目的，优时比可能会将您的个人信息转移给关联方或第三方。该等接受者为优时比的商业活动提供服务，例如提 供网站维护及商业、信息技术和技术的支持，且可能位于对个人信息保护要求相对较低的国家。但优时比会尽力确保您的个人信息被严格保密，并按照本隐私政策及 适用的隐私保护法律之规定处置您的个人信息。根据法律的有关规定，或者司法或行政机构的要求，优时比可能会披露您的个人信息。</p>
      <p class="texts">更改。您有责任对优时比系统中您个人信息进行更新。优时比也将不时要求您更新您的信息。</p>
      <p class="texts">您可通过访问优时比系统或向优时比邮箱（dataprivacyapac@ucb.com）发送电子邮件随时访问、修改或删除您的个人信息。</p>



    </div>
    <div class="cookis" v-show="showindex===3">
      <p class="title">Cookie 声明</p>
      <p class="title2">Cookie 声明</p>
      <p class="texts">优时比（UCB）将通过浏览器存储和记录您对于本网站的访问记录等相关信息，以便我们提供更好的服务。如果您同意，请点击“接受”按钮。谢谢。 </p>
    </div>

  </div>
</template>

<script>
import '../style/contact/PCstyle.scss'
import '../style/contact/APPstyle.scss'
export default {
  name: 'ContactPage',
  props: ['showindex'],
  data () {
    return {
      
    }
  },
  methods: {

  }
}
</script>

<style>

</style>