import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SerialView from '../views/SerialView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },{
    path: '/Serial',
    name: 'serial',
    component: SerialView
  }
]

const router = new VueRouter({
  routes
})

export default router
